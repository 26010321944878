.card {
  border: 0px;
}

.titlecardsblogs {
  font-size: 1.5rem;
  letter-spacing: 2px;
}

.blogRow {
  padding: 1.5rem;
  width: 75%;
  margin: auto;
}

@media (max-width: 768px) {
  .blogRow {
    padding: 1.5rem;
    padding-top: 0 !important;
    width: 100%;
    margin: auto;
  }
  .blogCol {
    padding: 20px 0;
  }
  .blogCard {
    border: 1px solid rgba(0, 0, 0, 0.125);
  }
  .oublogsheadma {
    padding: 0 !important;
  }

  .titlecardsblogs {
    font-size: 1.3rem;
  }
}
