.jumbosection {
  padding-top: 3rem;
  padding-right: 3rem;
  padding-left: 3rem;
}
.jumbo-img {
  width: 100%;
}
.jumbofont {
  font-size: 1.8rem;
}

@media (max-width: 768px) {
  .jumbosection {
    padding: 0;
    padding-top: 20px;
  }
  .jumbofont {
    font-size: 1.2rem;
    text-align: center;
  }
  .jumbopara {
    font-size: 0.7rem;
    text-align: center;
  }
  .jumbo-img {
    width: 60px;
  }
  .jumboimg-container {
    text-align: center;
  }
}
