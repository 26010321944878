.about-img-cards {
  transition: all 0.4s ease !important;
}

.about-img-cards:hover {
  transform: scale(0.95);
  filter: grayscale(100%);
}

.headban2 {
  padding: 0 !important;
  margin: 0 !important;
  letter-spacing: 5px;
}

@media (max-width: 768px) {
  .bc2 {
    height: 10vh !important;
  }
}
