.colshopI {
  overflow: hidden;
}

.hoverE {
  object-fit: cover;
  transition: all 0.1s ease;
}

.hoverE:hover {
  transform: scale(1.1);
}

/* .instagramSection {
  padding-top: 1rem;
  padding-bottom: 1rem;
} */
.instaRow {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 90%;
  margin: auto;
}

@media (max-width: 768px) {
  .instagramSection {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .instaRow {
    padding: 0%;
    width: 100%;
  }
  .colshopI {
    padding: 5px;
  }
}
