body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bebas {
  font-family: "Bebas Neue", cursive;
}

h1,
h2,
h3,
h4,
h5,
h6,
h7 {
  font-family: "Bebas Neue", cursive;
  letter-spacing: 1px;
}

code {
  font-family: "Roboto", sans-serif;
}

main {
  margin-top: 95px;
  min-height: 80vh;
}

.datetext2 {
  position: relative;
}
.datetext2:after {
  content: "";
  display: block;
  width: 90%;
  height: 1px;
  background: #cccccc;
  right: 0;
  top: 50%;
  position: absolute;
}

.custom-btn-buy {
  background-color: #e6e6e6;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

.custom-btn-buy:hover {
  background-color: #858585;
  color: white;
}

.Custom-headings {
  position: relative;
}
.Custom-headings:after {
  content: "";
  display: block;
  width: 40%;
  height: 1px;
  background: #cccccc;
  right: 0;
  top: 50%;
  position: absolute;
}

.Custom-headings:before {
  content: "";
  display: block;
  width: 40%;
  height: 1px;
  background: #cccccc;
  left: 0;
  top: 50%;
  position: absolute;
}

.Custom-headings-home {
  position: relative;
  font-size: 1.5rem;
}
.Custom-headings-home:after {
  content: "";
  display: block;
  width: 42%;
  height: 1px;
  background: #cccccc;
  right: 0;
  top: 50%;
  position: absolute;
}

.Custom-headings-home:before {
  content: "";
  display: block;
  width: 42%;
  height: 1px;
  background: #cccccc;
  left: 0;
  top: 50%;
  position: absolute;
}
.Custom-headings-home-na {
  position: relative;
  font-size: 1.5rem;
}
.Custom-headings-home-na:after {
  content: "";
  display: block;
  width: 28%;
  height: 1px;
  background: #cccccc;
  right: 0;
  top: 50%;
  position: absolute;
}

.Custom-headings-home-na:before {
  content: "";
  display: block;
  width: 28%;
  height: 1px;
  background: #cccccc;
  left: 0;
  top: 50%;
  position: absolute;
}
/* 
.Custom-headings-collection-name {
  position: relative;
}
.Custom-headings-collection-name:after {
  content: "";
  display: block;
  width: 50%;
  height: 1px;
  background: #cccccc;
  right: 0;
  top: 50%;
  position: absolute;
} */

/* .Contact-headings {
  position: relative;
}
.Contact-headings:after {
  content: "";
  display: block;
  width: 70%;
  opacity: 0.2;
  height: 1px;
  background: #cccccc;
  right: 0;
  top: 50%;
  position: absolute;
} */

.filter-texts {
  position: relative;
  font-weight: 900;
}
.filter-texts:after {
  content: "";
  display: block;
  width: 16vw;
  opacity: 0.8;
  height: 1px;
  background: #cccccc;
  right: 0;
  top: 50%;
  position: absolute;
}

.filter-texts-sort {
  position: relative;
  font-weight: 900;
}
.filter-texts-sort:after {
  content: "";
  display: block;
  width: 17vw;
  opacity: 0.8;
  height: 1px;
  background: #cccccc;
  right: 0;
  top: 50%;
  position: absolute;
}

.filter-texts-price {
  position: relative;
  font-weight: 900;
}
.filter-texts-price:after {
  content: "";
  display: block;
  width: 14vw;
  opacity: 0.8;
  height: 1px;
  background: #cccccc;
  right: 0;
  top: 50%;
  position: absolute;
}

.contant-form {
  border-radius: 0;
  border: 0;
  border-bottom: 2px solid #ccc;
}

@media (max-width: 768px) {
  main {
    /* padding-top: 80px; */
    margin-top: 80px;
  }
  .headingImage {
    object-fit: cover;
    height: 20vw;
  }
  .Custom-headings {
    position: relative;
    font-size: 1.2rem;
  }
  .Custom-headings:after {
    content: "";
    display: block;
    width: 25%;
    height: 1px;
    background: #cccccc;
    right: 0;
    top: 50%;
    position: absolute;
  }

  .Custom-headings:before {
    content: "";
    display: block;
    width: 25%;
    height: 1px;
    background: #cccccc;
    left: 0;
    top: 50%;
    position: absolute;
  }
  .Custom-headings-home:after {
    content: "";
    display: block;
    width: 30%;
    height: 1px;
    background: #cccccc;
    right: 0;
    top: 50%;
    position: absolute;
  }

  .Custom-headings-home:before {
    content: "";
    display: block;
    width: 30%;
    height: 1px;
    background: #cccccc;
    left: 0;
    top: 50%;
    position: absolute;
  }
  .Custom-headings-home-na:after {
    content: "";
    display: block;
    width: 20%;
    height: 1px;
    background: #cccccc;
    right: 0;
    top: 50%;
    position: absolute;
  }

  .Custom-headings-home-na:before {
    content: "";
    display: block;
    width: 20%;
    height: 1px;
    background: #cccccc;
    left: 0;
    top: 50%;
    position: absolute;
  }
  .filterDisplay {
    display: none;
  }
  .navbar-collapse {
    text-align: center;
  }
  .sideNav {
    padding: 20px 0 !important;
  }
}

/* About Page */

.reachimg {
  width: 30%;
}

.dispmabout {
  display: none;
}
.dispmaboutO {
  display: block;
}

.about-subhead {
  color: #353535;
  letter-spacing: 2px;
}

@media (max-width: 768px) {
  .wadjustabout {
    width: 100% !important;
  }
  .dispmabout {
    display: block;
  }
  .dispmaboutO {
    display: none;
  }
  .fontheada {
    font-size: 1.1rem !important;
  }
  .paadjust {
    padding: 10px 15px !important;
  }
  .mparaa {
    font-size: 1rem;
    font-weight: 300 !important;
  }
  .about-head {
    font-size: 1.5rem;
    padding: 0 20px;
  }
  .about-subhead {
    margin-top: 10px;
    font-size: 1rem !important;
  }
  .about-para {
    margin-top: 0px;
    font-size: 0.8rem;
  }
  .liner {
    margin-bottom: 10px !important;
  }
  .about-div {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .reachimg {
    width: 100%;
  }
  .rc-head {
    font-size: 1rem;
  }
  .rc-para {
    font-size: 0.7rem;
  }
  .filterbtn {
    display: block !important;
    margin-bottom: 10px;
  }
}

/* Contact page */

@media (max-width: 768px) {
  .contactp-0 {
    padding: 0 !important;
  }
  .contactp-2 {
    padding: 30px !important;
    width: 100% !important;
  }
  .contactp-s2 {
    padding: 10px !important;
  }
  .adjheight {
    height: auto !important;
  }
  .formContact {
    padding: 20px !important;
  }
}

/* Product Detail page */

.pcHide {
  display: none;
}
.phoneHide {
  display: block;
}

.mpadpd2 {
  margin-top: 20px;
}

@media (max-width: 768px) {
  .pcHide {
    display: block;
    padding: 1rem 2.5rem !important;
  }
  .phoneHide {
    display: none;
  }
  .mobtabsview {
    height: 30vh !important;
  }
  .mpadpd {
    margin-top: 20px;
  }
  .mpadpd2 {
    justify-content: start !important;
  }
}

/* Swiper */

.swiperPDpage {
  overflow: hidden;
}

.swiperPDpage .swiper-container {
  overflow: visible;
}

.swiperPDpage .swiper-button-next,
.swiperPDpage .swiper-button-prev {
  left: 50%;
  transform: rotate(90deg);
  transform-origin: left center;
}

.swiperPDpage .swiper-button-prev {
  top: 10px;
}

.swiperPDpage .swiper-button-next {
  top: 65vw;
  bottom: auto;
  z-index: 5000 !important;
  position: absolute !important;
}

.swiperPDpage .swiper-button-prev {
  display: none;
}

/* BlogPage */

@media (max-width: 768px) {
  .blogContainer {
    padding-top: 1.5rem !important;
  }
  .widthAdjust {
    width: 100% !important;
  }
  .blogmimg {
    height: auto !important;
    object-fit: contain !important;
  }
}

/* PRIVACY pAGES */

.policy-heading {
  position: relative;
}
.policy-heading:after {
  content: "";
  display: block;
  width: 75%;
  opacity: 0.8;
  height: 1px;
  background: #cccccc;
  right: 0;
  top: 50%;
  position: absolute;
}

@media (max-width: 768px) {
  .policy-heading:after {
    width: 50%;
  }
  .pdiv {
    width: 90% !important;
  }
  .h225 {
    height: 225px !important;
  }
}
@media (max-width: 350px) {
  .h225 {
    height: 300px !important;
  }
  .about-subhead {
    font-size: 0.7rem !important;
  }
}

/* Footer */
.hidefooterpc {
  display: none !important;
}

@media (max-width: 768px) {
  .hidefooterm {
    display: none !important;
  }
  .hidefooterpc {
    display: block !important;
  }
  .nopad {
    padding: 0px !important;
  }
}

/* Collection3 File */

@media (max-width: 768px) {
  .C3divheight {
    height: 23vh !important;
  }

  .bcat {
    font-size: 0.55rem;
  }

  .mensc3 {
    font-size: 1.2rem;
    padding: 10px 0px;
    margin-top: 10px;
  }
  .mnopadc3 {
    padding-left: 0;
    padding-right: 0;
  }

  .C33h {
    font-size: 0.7rem;
  }
}

.Custom-headings-collection-name {
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .dispmc3 {
    display: none;
  }
  .colc3mdisp {
    text-align: left !important;
  }
  .Custom-headings-collection-name {
    font-size: 1.3rem;
  }
  .mcartbtns {
    margin-top: 20px;
  }
  .contactp-3 {
    padding: 0 !important;
  }
}

.modal-90w {
  max-width: 100%;
  width: 100%;
  margin-top: 12vh;
}
