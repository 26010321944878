.bannerContainer {
  height: 70vh;
  background-size: cover !important;
  background-position: center !important;
}

.about-img-cards {
  transition: all 0.4s ease !important;
}

.about-img-cards:hover {
  transform: scale(0.95);
  filter: grayscale(100%);
}

.about-head {
  letter-spacing: 3px;
}

@media (max-width: 768px) {
  .bannerContainer {
    height: 30vh;
  }
}
