.nav-bg {
  background: transparent;
  transition: all 0.3s ease;
}
.nav-bg2 {
  background: rgb(240, 240, 240) !important;
  transition: all 0.3s ease;
}
.nav-bg:hover {
  background: rgb(240, 240, 240) !important;
}

.nav-bg:hover .hoverLinks {
  color: rgba(0, 0, 0, 0.5) !important;
}
.nav-bg:hover .hoverLinks:hover {
  color: rgba(0, 0, 0, 0.726) !important;
}
.navbar-dark .navbar-nav .nav-link {
  color: #e5dfd9;
}
.navbar-dark:hover .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}
.hoverLinks {
  color: #ffffff !important;
  transition: all 0.3s ease;
}
.hoverLinks2 {
  color: rgba(0, 0, 0, 0.65) !important;
  transition: all 0.3s ease;
}
.navFont {
  font-size: 1.3rem;
  transition: all 0.3s ease;
}
.navFont:hover {
  /* font-size: 1.5rem; */
  transform: scale(1.1);
}
.navFont2:hover {
  transform: scale(1.3);
}
.hoverLinks:hover {
  color: rgba(0, 0, 0, 0.726) !important;
}
.hoverLinks2:hover {
  color: rgba(0, 0, 0, 0.9) !important;
}

/* input[type="text"] {
  background-color: #3cbc8d;
  color: white;
} */

.customInput {
  border: 0px solid #ced4da;
  border-bottom: 1px solid #000;
  background-color: transparent;
  border-radius: 0px;
}

.customInput:focus {
  border: 0px solid #ced4da;
  border-bottom: 1px solid #000;
  background-color: transparent;
  border-radius: 0px;
  box-shadow: none;
}

.customInputButton {
  border-bottom: 1px solid #000;
  color: #000;
  padding: 6px;
}

.navlogo {
  width: 120px;
}

.mdisplaynav {
  display: none !important;
}

@media (max-width: 768px) {
  .navlogo {
    width: 100px;
  }
  .mdisplaynav {
    display: block !important;
  }
  .mdisplaynavnone {
    display: none !important;
  }
}

@media (min-width: 1800px) {
  .header-container {
    max-width: 75% !important;
  }
}

.searchInput:focus {
  box-shadow: none;
}
