.carousel2 {
  width: 900px;
  height: 500px;
  margin: auto;
}
.tipcarousal {
  position: relative;
  width: 80%;
  margin: auto;
  right: 0;
  left: 0;
}
.tipsection {
  padding: 0 0;
}
.extrapad {
  padding: 3rem;
}
.tipsection h3 {
  padding: 5px;
}
.tipsection p {
  margin-bottom: 10px;
}

@media (max-width: 900px) {
  .carousel2 {
    width: auto;
    height: auto;
  }
  .tipcarousal {
    position: relative;
    width: 100%;
  }
  .tipsection {
    padding: 0.5rem 0;
  }
  .extrapad {
    padding: 10px;
  }
  .tipsection h3 {
    padding: 5px;
    font-size: 1.1rem;
  }
  .tipsection p {
    margin-bottom: 30px;
    font-size: 0.8rem;
  }
  .tipheadma {
    padding-bottom: 0 !important;
  }
}
