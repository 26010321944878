/* .testdiv {
  padding-top: 0.5rem;
  padding: 3rem;
} */
.testcarousal {
  padding: 0rem 3rem 3rem 3rem;
  width: 70%;
  margin: auto;
}

@media (max-width: 768px) {
  .testdiv {
    padding: 0;
  }
  .testcarousal {
    padding: 1rem 0rem 1rem 0rem;
    margin-bottom: 1.5rem;
    width: 100% !important;
  }
  .testheadma {
    padding-bottom: 0 !important;
  }
}
