.newArow {
  width: 100%;
  margin: auto;
}

.paddingPT {
  padding-top: 10px;
}

.mpadAdjustE {
  padding: 1.5rem 3rem 1rem 3rem !important;
}

@media (max-width: 768px) {
  .newArow {
    width: 100%;
  }
  .nopaddingonparent {
    padding: 0;
  }
  .mpadAdjustE {
    padding: 1rem 3rem 3rem 3rem !important;
  }
  .paddingPT {
    padding-top: 0px;
  }
  .adjustrowpp {
    margin: 1rem 0 0 0 !important;
  }
  .adjustdivpp {
    padding-bottom: 0.5rem !important;
  }
}

/* .caption-box3 {
  position: absolute;
  top: 20px;
  left: 20px;
}

.C-title3 {
  position: relative;
  margin-top: 24vw;
}

.C-title3 h1 {
  color: #c8a44c !important;
  font-size: 4rem;
  text-transform: uppercase;
  text-align: right;
  text-shadow: #c8a44c 1px 0 5px;
  transition: all 0.3s ease;
}

.C-title3:hover h1 {
  text-shadow: #ffdc8a 1px 0 5px;
}

@media (max-width: 768px) {
  .caption-box3 {
    position: absolute;
    right: 0;
    top: 03%;
  }
  .C-title3 h1 {
    color: #c8a44c !important;
    font-size: 2rem;
    text-transform: uppercase;
    text-align: right;
    transition: all 0.3s ease;
  }
  .C-title3 {
    margin-top: 130vw;
    margin-right: 10px;
  }
} */
