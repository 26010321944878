.datetext {
  position: relative;
  font-size:0.8rem;
}
.datetext:after {
  content: "";
  display: block;
  width: 77%;
  height: 1px;
  background: #cccccc;
  right: 0;
  top: 50%;
  position: absolute;
}
