:root {
  --swiper-theme-color: #c8a44c;
}
.swiper-pagination-bullet {
  background: white;
  opacity: 0.8;
}

.swiper-pagination-bullet-active {
  background: #c8a44c;
}

.bannerLogo {
  width: 55px;
}

.shoespan {
  font-size: 6vw;
}

@media (max-width: 768px) {
  .shoespan {
    font-family: "Great Vibes";
    text-transform: capitalize;
    font-size: 3rem;
  }
}

/* 1 */

.C-logo {
  position: absolute;
  right: 0;
}

.caption-box {
  position: absolute;
  right: 5.9vw;
  top: 06%;
}

.C-title {
  position: relative;
  margin-top: 16vw;
}

.C-title h1 {
  color: #c8a44c !important;
  font-size: 4vw;
  text-transform: uppercase;
  text-align: left;
  /* text-shadow: #c8a44c 1px 0 5px; */
  transition: all 0.3s ease;
}

.C-title:hover h1 {
  /* text-shadow: #ffdc8a 1px 0 5px; */
}

@media (max-width: 768px) {
  .bannerLogo {
    width: 30px;
  }
  .caption-box {
    position: absolute;
    right: 0;
    top: 03%;
    width: 100%;
  }
  .C-title h1 {
    color: #c8a44c !important;
    font-size: 2rem;
    text-transform: uppercase;
    text-align: center;
    transition: all 0.3s ease;
  }
  .C-title {
    margin-top: 53vh;
  }
  .C-logo {
    margin-right: 10px;
  }
  .C-logo2 {
    margin-right: 10px;
  }
  .C-logo3 {
    margin-right: 10px;
  }
}

/* 2 */
.C-logo2 {
  position: absolute;
  right: 0;
}

.caption-box2 {
  position: absolute;
  right: 5.9vw;
  top: 06%;
}

.C-title2 {
  position: relative;
  margin-top: 16vw;
}

.C-title2 h1 {
  /* color: #3b3b3b !important; */
  color: #000000 !important;
  font-size: 4vw;
  text-transform: uppercase;
  text-align: left;
  transition: all 0.3s ease;
  /* text-shadow: #ffdc8a 1px 0 5px; */
}

.C-title2:hover h1 {
  /* text-shadow: #ffdc8a 1px 0 5px; */
}
@media (max-width: 768px) {
  .caption-box2 {
    position: absolute;
    right: 0;
    top: 03%;
    width: 100%;
  }
  .C-title2 h1 {
    font-size: 2rem;
    text-transform: uppercase;
    text-align: center;
    transition: all 0.3s ease;
  }
  .C-title2 {
    margin-top: 53vh;
  }
}

/* 3 */

.C-logo3 {
  position: absolute;
  right: 0;
}

.caption-box3 {
  position: absolute;
  right: 50px;
  top: 10%;
}

.C-title3 {
  position: relative;
  margin-top: 10vw;
}

.C-title6 {
  position: relative;
  margin-top: 12vw;
}

.C-title3 h1 {
  color: #3b3b3b !important;
  font-size: 4vw;
  text-transform: uppercase;
  text-align: left;
  transition: all 0.3s ease;
  margin-right: 15vw;
}
.C-title6 h1 {
  color: #3b3b3b !important;
  font-size: 4vw;
  text-transform: uppercase;
  text-align: left;
  transition: all 0.3s ease;
  margin-right: 15vw;
}

.C-title3:hover h1 {
  /* text-shadow: #ffdc8a 1px 0 5px; */
}
.bmadjust3 {
  display: none;
}

@media (max-width: 768px) {
  .caption-box3 {
    position: absolute;
    right: 0;
    top: 03%;
    width: 100%;
  }
  .C-title3 h1 {
    font-size: 1.7rem;
    text-transform: uppercase;
    text-align: center;
    transition: all 0.3s ease;
    margin-right: 0;
    color: #000000 !important;
    /* text-shadow: rgb(255, 255, 255) 1px 5px 3px; */
    padding: 0 20px;
  }
  .C-title3 {
    margin-top: 53vh;
  }
  .bmadjust3 {
    display: block;
  }
  .bmadjust31 {
    display: none;
  }
  .bmadjust312 {
    display: block;
  }
}

/* 4 */

.C-logo {
  position: absolute;
  right: 0;
}

/* .caption-box {
  position: absolute;
  right: 50px;
  top: 06%;
} */

.C-title4 {
  position: relative;
  margin-top: 16vw;
}

.C-title4 h1 {
  color: #384440 !important;
  font-size: 4vw;
  text-transform: uppercase;
  text-align: left;
  /* text-shadow: #c8a44c 1px 0 5px; */
  transition: all 0.3s ease;
}

.C-title4:hover h1 {
  /* text-shadow: #ffdc8a 1px 0 5px; */
}

@media (max-width: 768px) {
  .bannerLogo {
    width: 30px;
  }
  .caption-box {
    position: absolute;
    right: 0;
    top: 03%;
    width: 100%;
  }
  .C-title4 h1 {
    /* color: #ffffff !important; */
    font-size: 2rem;
    text-transform: uppercase;
    text-align: center;
    transition: all 0.3s ease;
  }
  .C-title4 {
    margin-top: 53vh;
  }
}

@media (max-width: 1400px) and (min-width: 1200px) {
  .screen1339 {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: bottom right;
  }
}
/* ​ @media (max-width: 1399px) {
  .screen1339 {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: bottom right;
  }
} */

/* Hide Phone */
@media (max-width: 768px) {
  .hidePhoneBannerText {
    display: none !important;
  }
} ;
