.main-title1 {
  color: #2d2d2d;
  text-align: center;
  text-transform: capitalize;
  padding: 0.7em 0;
}

.title1 {
  color: #1a1a1a;
  text-align: center;
  margin-bottom: 10px;
}

.content1 {
  position: relative;
  margin: auto;
  overflow: hidden;
}

.content1 .content-overlay1 {
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  height: 15%;
  width: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 1;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

/* .content:hover .content-overlay {
    opacity: 1;
  } */

.content-details1 {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 94% !important;
  left: 50%;
  opacity: 1;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

/* .content1:hover .content-details1 {
  top: 90% !important;
  left: 50%;
  opacity: 1;
} */

.content-details1 h5 {
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.15em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
  font-size: 1rem;
}

.content-details1 p {
  color: #fff;
  font-size: 0.8em;
}

.advantageRow {
  padding: 3rem 0;
}

.advantageCol {
  padding: 5px;
}

@media (max-width: 768px) {
  .advantageRow {
    padding-top: 1.5rem;
    padding-bottom: 0rem !important;
  }
  .content-details1 h5 {
    font-size: 0.55rem;
  }
  .nplr {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
