/* .hoverup {
  transition: all 0.3s ease;
}

.hoverup:hover {
  transform: scale(1.2);
} */

.colCate {
  padding: 5px;
}

.main-title {
  color: #2d2d2d;
  text-align: center;
  text-transform: capitalize;
  padding: 0.7em 0;
}

.title {
  color: #1a1a1a;
  text-align: center;
  margin-bottom: 10px;
}

.content {
  position: relative;
  margin: auto;
  overflow: hidden;
}

.content .content-overlay {
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  height: 15%;
  width: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 1;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

/* .content:hover .content-overlay {
  opacity: 1;
} */

.content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 94% !important;
  left: 50%;
  opacity: 1;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

/* .content:hover .content-details {
  top: 90% !important;
  left: 50%;
  opacity: 1;
} */

.content-details h4 {
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.15em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
  font-size: 1.1rem;
}

/* .content:hover h4 {
  color: rgb(0, 0, 0);
  background-color: rgb(206, 206, 206);
} */

.fadeIn-bottom {
  top: 80%;
}

.fadeIn-top {
  top: 20%;
}

.fadeIn-left {
  left: 20%;
}

.fadeIn-right {
  left: 80%;
}

@media (max-width: 768px) {
  .colCate {
    padding: 5px;
  }
  /* .content-details h4 {
    font-size: 1rem;
  } */
}

@media (max-width: 768px) {
  .content-details h4 {
    font-size: 0.7rem;
  }
  .content-details {
    top: 96% !important;
  }
}
