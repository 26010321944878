.buybtn {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}

.cardkatext {
  font-size: 13px;
}

.mobfontA {
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .card-body {
    text-align: center;
    padding: 0.5rem;
  }
  /* .buybtn {
    margin-top: 5px;
  } */
  .mobfontA {
    font-size: 13px;
    line-height: 1.2;
  }
  .mobcolAdjust {
    padding-left: 0;
    padding-right: 0.5rem;
  }
  .cardkatext {
    font-size: 10px;
  }
  .cardkatextprice {
    font-size: 12px;
  }
}

@media (max-width: 1399px) {
  .cardkatext {
    font-size: 11px !important;
  }
}
