footer {
  background-color: black;
  color: white;
}

.flexprop {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
}
.flexprop2 {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  /* align-content: center; */
  flex-direction: column;
}

.whitelogo {
  /* filter: brightness(0) invert(1); */
  width: 40%;
}
.centerFooter {
  text-align: center;
}
.footerIcons {
  color: white;
  transition: all 0.5s ease;
}
.footerIcons:hover {
  color: rgb(161, 161, 161);
}

.verticleText {
  writing-mode: vertical-lr;
  text-orientation: mixed;
  transform: rotate(180deg);
  border-left: 1px solid rgb(200, 164, 76);
  padding-left: 10px;
  color: rgb(212, 212, 212);
}

.footerLink {
  color: rgb(161, 161, 161);
  text-decoration: none;
  transition: 0.2s ease;
  font-size: 13px;
}

.footerLink:hover {
  color: #c8a44c;
}
.padadjust {
  padding: 3rem;
}
.dispnonepc {
  display: none;
}

.socialRow {
  display: flex;
  align-items: center;
  height: 68%;
}

.input22 {
  border: none;
  border-bottom: 2px solid black;
}

.input22Button {
  border: none;
  border-bottom: 2px solid black;
}

@media (max-width: 768px) {
  .socialRow {
    height: 120%;
  }
  .whitelogo {
    width: 45%;
  }
  .textcenterFooter {
    text-align: left !important;
  }
  .verticleText {
    font-size: 0.9rem;
  }
  /*.mobdispNode {
    display: none;
  } */
  .dispnone {
    display: none;
  }
  .mobul {
    padding-left: 50px;
  }
  /* .footermarginbottom {
    margin-top: 20px;
  } */
  .whitelogo {
    padding-top: 20px;
  }
  .mtopfooter {
    margin-top: 30px;
  }
  .padadjust {
    padding: 1rem;
  }
  .footerIcons {
    font-size: 1.2rem !important;
  }
  .footerIcons2 {
    font-size: 1rem !important;
  }
  .paddingfzero {
    padding: 0 !important;
  }
  .dispnonepc {
    display: flex;
  }
  .notcenter {
    text-align: left !important;
  }
}
