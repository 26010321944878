.input2 {
  border: 0px solid #ced4da;
  border: 1px solid #000;
  border-right: none;
  background-color: #e6e6e6;
  border-radius: 0px;
}

.input2:focus {
  border: 0px solid #ced4da;
  border: 1px solid #000;
  border-right: none;
  background-color: #e6e6e6;
  border-radius: 0px;
  box-shadow: none;
}

.input2Button {
  border-radius: 0px;
  border-left: none;
}

/* .childRowFTop {
  margin-left: 3rem;
} */
.childRowFTop h2 {
  font-size: 1.9rem;
}

.rowFTop {
  padding-top: 1rem;
  padding-bottom: 1rem;

  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

@media (max-width: 768px) {
  /* .childRowFTop {
    margin-left: 0;
  } */

  .childRowFTop h2 {
    font-size: 1.2rem;
    text-align: center;
    font-weight: 900;
  }
  .childRowFTop p {
    display: none;
  }
  .rowFTop {
    padding: 1rem 0;
  }
}
